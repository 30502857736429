import { RecipeIngredient, Recipe } from "../types"

/**
 *
 * For Spoonacular
 */
export const recipeSuggestionsTransformer = (recipes): Recipe[] => {
  console.log("recipe og data", recipes)
  return recipes.map(recipe => {
    return {
      id: recipe.id,
      img: recipe.image,
      title: recipe.title,
      missingIng: ingredientTransformer(recipe.missedIngredients),
      availdIng: ingredientTransformer(recipe.usedIngredients),
      unusedIng: ingredientTransformer(recipe.unusedIngredients),
      likes: recipe.likes,
      time: null,
      category: null,
      people: null,
      comments: [],
    }
  })
}

const ingredientTransformer = (ingredients): RecipeIngredient[] => {
  return ingredients.map(ing => {
    return {
      id: ing.id,
      unit: ing.unit,
      amount: ing.amount,
      title: ing.name,
      desc: ing.original,
    }
  })
}

/**
 * For edamane
 */
export const recipeSuggestionsTransformerAlt = recipes => {
  console.log("recipe", recipes)
  return recipes.hits.map(recipe => {
    return {
      id: null,
      img: recipe.recipe.image,
      title: recipe.recipe.label,
      missingIng: [],
      availdIng: recipe.recipe.ingredients.map(ing => ing.text),
      unusedIng: [],
      likes: 0,
    }
  })
}
