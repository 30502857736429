import {
  recipeSuggestionsTransformer,
  recipeSuggestionsTransformerAlt,
} from "./transformers"

export const searchRecipes = ingredients => {
  const searchUrl = `https://${"us"}.openfoodfacts.org/cgi/search.pl`
  const contentCategory = "breakfast_cereal"
  const query = `${searchUrl}
  ?action=process
  &tagtype_0=categories
  &tag_contains_0=contains
  &tag_0=${contentCategory}
  &tagtype_1=nutrition_grades
  &tag_contains_1=contains
  &tag_1=A
  &additives=without
  &ingredients_from_palm_oil=without`
  fetch(query)
}

export const searchMainIngredient = ingredient => {
  const searchUrl = `https://www.themealdb.com/api/json/v1/1/filter.php?i=${ingredient}`
}

/**
 *
 * DEPRACATED: Cant search closest match based on ingredients
 */
export const searchByIngredientsAlt = async ingredients => {
  const APP_ID = process.env.APP_ID
  const APP_KEY = process.env.APP_KEY
  const ingredientTransformer = ingredients.map(ing =>
    ing === ingredients[0] ? ing : `+${ing}`
  )
  const searchUrl = `https://api.edamam.com/search?q=${ingredientTransformer}&app_id=${APP_ID}&app_key=${APP_KEY}`
  return await fetch(searchUrl)
    .then(res => res.json())
    .then(data => {
      const test = recipeSuggestionsTransformerAlt(data)
      console.log("test", test)
      return test
    })
    .catch(err => console.log("error", err))
}

export const searchByIngredients = async ingredients => {
  const API_KEY = process.env.SPOONACULAR_KEY
  const ingredientTransformer = ingredients.map(ing =>
    ing === ingredients[0] ? ing : `+${ing}`
  )

  const searchUrl = `https://api.spoonacular.com/recipes/findByIngredients?ingredients=${ingredientTransformer}&number=10&ranking=2&apiKey=${API_KEY}`
  return await fetch(searchUrl)
    .then(res => res.json())
    .then(data => recipeSuggestionsTransformer(data))
}

export const getIngredientsList = async () => {
  fetch(
    "https://us.openfoodfacts.org/cgi/search.pl?action=process&tagtype_0=categories&tag_contains_0=contains&tag_0=breakfast_cereals",
    {
      headers: {
        "User-Agent": "TastyFridge - Web - 0.0.1",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then(res => res.json())
    .then(data => console.log(data))
    .catch(e => console.log("error", e))
}
