import React, { useState } from "react"
import { Recipe } from "../types"
import mixpanel from "mixpanel-browser"
import { Icon, InlineIcon } from "@iconify/react"
import clipboardListOutline from "@iconify/icons-mdi/clipboard-list-outline"
import webIcon from "@iconify/icons-mdi/web"
import checkIcon from "@iconify/icons-bi/check"
import crossSmall from "@iconify/icons-gridicons/cross-small"
import infoIcon from "@iconify/icons-octicon/info"

const Ingredient = props => {
  const { ingredient } = props
  return (
    <div>
      <h3>{ingredient.name}</h3>
      <p>{ingredient.quantity}</p>
    </div>
  )
}

const IngredientModal = ({ modal, toggleModal, card }) => {
  return (
    <div
      className={`${modal} w-full absolute inset-x-0 top-0 bg-white my-8 rounded shadow flex h-full`}
    >
      <button
        onClick={() =>
          modal === "hidden" ? toggleModal("block") : toggleModal("hidden")
        }
      >
        <Icon
          icon={infoIcon}
          style={{ fontSize: "30px" }}
          className="w-6 h-6 mr-1 rounded-full mr-1 inline-block border"
        />
      </button>
      <ul className="text-sm pl-1 m-0 w-1/3">
        {card.availdIng.map(ing => (
          <li className="text-green-600 m-0 block">
            <InlineIcon
              icon={checkIcon}
              style={{ fontSize: "30px" }}
              className="w-1/3 inline"
            />
            {ing.title}
          </li>
        ))}
      </ul>
      <ul className="text-sm pl-1 m-0 w-1/3">
        {card.missingIng.map(ing => (
          <li className="text-red-600 m-0">
            <InlineIcon
              icon={crossSmall}
              style={{ fontSize: "30px" }}
              className="w-1/3 inline"
            />
            {ing.title}
          </li>
        ))}
      </ul>
    </div>
  )
}

const RecipeCard = (card: Recipe) => {
  const [modal, toggleModal] = useState("hidden")
  return (
    <div className="w-full h-auto lg:max-w-full my-4 relative border-r-0 border-b border-l border-t pt-2 border-gray-400 lg:border-l lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-l">
      <h3 className="block w-full text-gray-900 object-contain font-bold text-xl text-left lg:text-center lg:pl-2 pr-4 m-0 text-center">
        {card.title}
      </h3>
      <div className="flex flex-no-wrap ">
        <div className=" w-3/4 h-48 px-4 py-1 flex flex-col justify-between leading-normal">
          <div className="mb-2">
            <div className="text-sm text-gray-600">
              <div className="inline-block w-1/2 text-left">
                Have: {card.availdIng.length}
              </div>
              <div className="inline-block w-1/2 text-left">
                Missing: {card.missingIng.length}
              </div>
            </div>
          </div>
          <div className="flex h-1/3">
            <button
              onClick={() =>
                modal === "hidden"
                  ? toggleModal("block")
                  : toggleModal("hidden")
              }
            >
              <Icon
                icon={infoIcon}
                style={{ fontSize: "30px" }}
                className="w-6 h-6 mr-1 rounded-full mr-1 inline-block border"
              />
            </button>
            <ul className="text-sm pl-1 m-0 w-1/3">
              {card.availdIng.map(ing => (
                <li className="text-green-600 m-0 block truncate">
                  <InlineIcon
                    icon={checkIcon}
                    style={{ fontSize: "30px" }}
                    className="w-1/3 inline"
                  />
                  {ing.title}
                </li>
              ))}
            </ul>
            <ul className="text-sm pl-1 m-0 w-1/3">
              {card.missingIng.map(ing => (
                <li className="text-red-600 m-0 truncate">
                  <InlineIcon
                    icon={crossSmall}
                    style={{ fontSize: "30px" }}
                    className="w-1/3 inline"
                  />
                  {ing.title}
                </li>
              ))}
            </ul>
          </div>
          <button
            className="rounded p-2 bg-bgGreen text-white mx-2 font-semibold"
            onClick={() => mixpanel.track(`Searched For a Recipe`)}
          >
            <a
              href={`https://www.google.com/search?q=${card.title}`}
              target="_blank"
            >
              <Icon
                icon={webIcon}
                style={{ fontSize: "30px", color: "#408AF8" }}
                className="inline"
              />
              <span>Search the Web</span>
            </a>
          </button>
        </div>
        <div
          className="w-1/4 lg:h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-r text-center overflow-hidden"
          style={{ backgroundImage: `url('${card.img}')` }}
          title={`image of ${card.title}`}
        ></div>
      </div>
      <IngredientModal modal={modal} toggleModal={toggleModal} card={card} />
    </div>
  )
}

const RecipeSuggestions = ({ recipes }) => {
  return (
    <ul className="m-0">
      {recipes.map((recipe, i) => (
        <RecipeCard {...recipe} key={"recipeCard" + i} />
      ))}
    </ul>
  )
}

export default RecipeSuggestions
