// Gatsby supports TypeScript natively!
import React, { useEffect, useState } from "react"
import { PageProps, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Recipe } from "../types"
import { searchByIngredients } from "../api/searchAPI"
import RecipeSuggestions from "../components/recipeSuggestions"
import { getIngredients } from "../utils/storage"

const MyRecipeSuggestions = (props: PageProps) => {
  const [recipes, setRecipes] = useState<null | Recipe[]>(null)
  useEffect(() => {
    // function that gets the ingredients from localstorage

    const ingredients = getIngredients().map(ing => ing.name)

    searchByIngredients(ingredients).then(data => setRecipes(data))
  }, [])
  return (
    <Layout>
      <SEO title="My Recipe Suggestions" />
      <h1>Suggestions</h1>
      {recipes ? <RecipeSuggestions recipes={recipes} /> : <h1>No recipes</h1>}
    </Layout>
  )
}

export default MyRecipeSuggestions
